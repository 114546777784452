@font-face {
font-family: 'sctoGrotesk';
src: url(/_next/static/media/c488c9b0c96a3be1-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'sctoGrotesk';
src: url(/_next/static/media/cdcdd298e2e137ba-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'sctoGrotesk';
src: url(/_next/static/media/7b3929eb415dc839-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'sctoGrotesk Fallback';src: local("Arial");ascent-override: 77.98%;descent-override: 19.50%;line-gap-override: 23.78%;size-adjust: 102.59%
}.__className_f88bef {font-family: 'sctoGrotesk', 'sctoGrotesk Fallback'
}.__variable_f88bef {--font-scto-grotesk: 'sctoGrotesk', 'sctoGrotesk Fallback'
}

